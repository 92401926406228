<template>
	<div> 
		<Barcode />
	</div>
</template>

<script>
// @ is an alias to /src
import Barcode from '@/components/reports/barcode/Barcode.vue'

export default {
	name: 'BarCode',
	components: {
		Barcode,
	},
	data() {
		return {
			
		}
	},
	mounted() {

},
	computed: {

},
	methods: {
		
	},
}
</script>
