<template>
  <div class="container px-6 mx-auto grid dark:bg-gray-900">
    <h2
      class="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200 text-left"
    >
      BarCode
    </h2>
    <form @submit.prevent="" autocomplete="off">
      <div class="grid gap-6 mb-8 md:grid-cols-2 lg:grid-cols-3">
        <!-- Card -->
        <div
          class="flex items-center p-4 bg-white rounded-lg shadow dark:bg-gray-800"
        >
          <div
            class="px-3 py-2 mr-4 text-blue-500 bg-blue-100 rounded-full dark:text-blue-100 dark:bg-blue-500"
          >
            <i class="icon-users"></i>
          </div>
          <div>
            <Multiselect
              v-model="employees"
              tag-placeholder="Add"
              placeholder="Empleados"
              label="name"
              track-by="code"
              :options="objEmployees"
              :multiple="true"
              :taggable="true"
              @tag="addTag"
            ></Multiselect>
          </div>
        </div>
        <!-- Card -->

        <!-- Card -->
        <div
          class="flex items-center p-4 bg-white rounded-lg shadow dark:bg-gray-800"
        >
          <div
            class="px-4 py-2 mr-4 text-green-500 bg-green-100 rounded-full dark:text-green-100 dark:bg-green-500"
          >
            <i class="icon-doc-text"></i>
          </div>
          <div>
            <button
              type="button"
              @click.prevent="processReport"
              class="bg-green-600 hover:bg-green-700 px-3 py-1 rounded text-white"
            >
              Generar code
            </button>
          </div>
        </div>
        <!-- Card -->
        <!-- Card -->
        <div
          class="flex items-center p-4 bg-white rounded-lg shadow dark:bg-gray-800"
        >
          <div
            class="px-3 py-2 mr-4 text-blue-500 bg-blue-100 rounded-full dark:text-blue-100 dark:bg-blue-500"
          >
            <i class="icon-barcode"></i>
          </div>
          <div>
            <img :src="imgBase64" @click="dwImage($event)" alt="" />
          </div>
        </div>
        <!-- Card -->
      </div>
    </form>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { mapState } from "vuex";
import Axios from "axios";

export default {
  name: "Barcode",
  components: {
    Multiselect,
  },
  props: {},
  data() {
    return {
      URL: process.env.VUE_APP_URL,
      employees: [],
      objEmployees: [],
      imgBase64: "",
    };
  },
  mounted() {
    this.getEmployees();
  },
  computed: {
    ...mapState(["user", "access_token"]),
  },
  methods: {
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      // this.options.push(tag)
      // this.transaction.push(tag)
    },
    async getEmployees() {
      const URL = `${this.URL}hr-employees/${this.user.countryId}/${this.user.companyId}`;
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.access_token.replace(/['"]+/g, "")}`,
        },
      };

      try {
        let employees = await Axios.get(URL, fetchConfig);

        this.objEmployees = employees.data.map((item) => {
          return { name: item.shortName, code: item.staffCode };
        });

        console.log(this.objEmployees);
      } catch (error) {
        Object.keys(error).forEach((key) => {
          // console.log(error[key])
        });
      }
    },
    async processReport() {
      let access = confirm(
        "Desea realizar esta accion? Si continua con el proceso el QR anterior, quedara invalidado!"
      );
      if (access) {
        if (this.employees[0]?.code) {
          let base64 = "data:image/png;base64, ";

          let barCode = await Axios.get(
            `${this.URL}barcode-create/${this.employees[0]?.code}`
          );

          this.imgBase64 = base64 + barCode.data;
          // console.log(this.imgBase64)
        } else {
          alert("Debe seleccionar un empleado");
          return;
        }
      }
    },
    dwImage(event) {
      const downloadLink = document.createElement("a");
      downloadLink.href = event?.srcElement?.currentSrc;
      downloadLink.download = this.employees[0]?.name;
      downloadLink.click();
    },
  },
};
</script>

<style></style>
